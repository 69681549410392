/* eslint-disable prefer-const */
<template>
  <div class="container-fluid p-4">
    <div class="row">
      <div class="col-12">
        <div class="row text-center">
          <canvas
            id="canvaScreen"
            style="width: 100px;"
            hidden
          />
          <div class="col-6">
            <video
              id="videoScreen"
              width="100%"
              class="img-fluid"
            >
              <source
                :src="videoScreen"
                type="video/mp4"
              >
            </video>
          </div>
          <div class="col-6">
            <video
              id="videoCam"
              width="100%"
              class="img-fluid"
            >
              <source
                :src="videoWebcam"
                type="video/mp4"
              >
            </video>
          </div>

          <div class="col-12 text-start">
            <button
              id="btnPlayPause"
              class="btn btn-info"
              title="play"
              accesskey="P"
              @click="playPauseVideo()"
            >
              <i class="fa-regular fa-circle-play" />
            </button>

            <button
              id="btnStop"
              class="btn btn-info"
              title="stop"
              accesskey="X"
            >
              <i class="fa-regular fa-circle-stop" />
            </button>

            <button
              id="btnReplay"
              class="btn btn-info"
              title="replay"
              accesskey="R"
              onclick="replayVideo();"
            >
              <i class="fa-solid fa-reply-all" />
            </button>

            <button
              id="btnFullScreen"
              class="btn btn-info"
              title="toggle full screen"
              accesskey="T"
              onclick="toggleFullScreen();"
            >
              [&nbsp;&nbsp;]
            </button>

            <!--<button id='btnMute' class='btn btn-info' title='mute' onclick='muteVolume();'><i class="fa-solid fa-volume-xmark"></i></button>-->
          </div>
        </div>

        <!--<input style="width:50%" type="range" id="volume-bar" title="volume" min="0" max="1" step="0.1" value="1">-->

        <div class="row text-center mt-4">
          <div class="col-2">
            <!-- <img
              id="thumbnail-start"
              class="img-fluid"
              :src="thumb_start"
              width="100%"
              alt="video thumbnail"
            > -->
            <div class="text-start text-white bg-info rounded-bottom p-2">
              INICIO • {{ start_time_string }}
            </div>
          </div>
          <div class="col-8">
            <progress
              id="progress-bar"
              class="col-12"
              min="0"
              max="100"
              value="0"
            >
              0% played
            </progress>
            <div id="slider" />
            <div class="mt-2">
              <button
                v-if="!showSendBtn && !loading"
                id="btTrim"
                type="button"
                class="btn btn-warning"
                :disabled="loading"
                @click="trimVideo()"
              >
                <i class="fa-solid fa-scissors" /> Aparar Video
              </button>
              <button
                v-if="showSendBtn"
                class="btn btn-success"
                @click="goBack()"
              >
                Finalizar
              </button>
              <div v-if="loading">
                O corte do vídeo pode levar alguns minutos. Aguarde...
              </div>
            </div>

            <div class="col-12">
              <code><span
                id="startspan"
                class="start"
              >{{ start_time_string }}</span> / <span
                id="finishspan"
                class="finish"
              >{{ final_time_string }}</span></code>
            </div>
          </div>

          <div class="col-2">
            <!-- <img
              id="thumbnail-finish"
              class="img-fluid"
              :src="thumb_finish"
              width="100%"
              alt="video thumbnail"
            > -->
            <div class="text-start text-white bg-warning rounded-bottom p-2">
              FINAL • <span class="finish fw-bold font-monospace">
                <!-- <?php echo gmdate("H:i:s", $seconds) ?> --> {{ final_time_string }}
              </span>
            </div>
          </div>
        </div>

        <!--
                    <div class="row text-center mt-3">
                        <div class="col-6">
                            INICIO: <code><span class="start">00:00:01</span></code>
                        </div>
                        <div class="col-6">
                            FINAL: <code><span class="finish"><?php echo gmdate("H:i:s", $seconds) ?></span></code>
                        </div>
                    </div>

                    <div class="row text-center mt-3">

                    </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Trim from '../../js/player-trim'
import noUiSlider from 'nouislider'
import 'nouislider/dist/nouislider.css'
import getBlobDuration from 'get-blob-duration'
import CoursesServices from './coursesServices'

export default {
  props: {
    videoSrc: {
      type: Blob,
      required: true
    },
    videoCamSrc: {
      type: String,
      required: true
    },
    videoFile: {
      type: Blob,
      required: true
    },
    screen: {
      type: File
    },
    webcam: {
      type: File
    }
  },
  data() {
    return {
      thumb_start: '',
      thumb_finish: '',
      start_time: 0,
      final_time: 0,
      total_time: 0,
      start_time_string: '',
      final_time_string: '',
      loading: false,
      sending: false,
      showSendBtn: false,
      videoScreen: this.videoSrc,
      videoWebcam: this.videoCamSrc,
      video_cam: '', // send to vimeo
      video_screen: '' // send to vimeo
    }
  },
  watch: {
    final_time() {
      console.log(this.final_time)
      if (!this.slider.noUiSlider) {
        noUiSlider.create(this.slider, {
          start: [this.start_time, this.final_time],
          connect: true,
          step: 1,
          range: {
            min: 1000,
            max: this.final_time
          }
        })

        this.slider.noUiSlider.on('end', () => {
          // eslint-disable-next-line prefer-destructuring
          const arrayTimes = this.slider.noUiSlider.get(true)
          // eslint-disable-next-line prefer-destructuring
          this.start_time = arrayTimes[0]
          // eslint-disable-next-line prefer-destructuring
          this.final_time = arrayTimes[1]
          this.start_time_string = new Date(arrayTimes[0]).toISOString().substr(11, 8)
          this.final_time_string = new Date(arrayTimes[1]).toISOString().substr(11, 8)
        })
      }

      const arrayTimes = this.slider.noUiSlider.get(true)

      this.final_time_string = new Date(arrayTimes[1]).toISOString().substr(11, 8)
      this.start_time_string = new Date(arrayTimes[0]).toISOString().substr(11, 8)
    },
  },
  async mounted() {
    const video = document.getElementById('videoScreen')
    const videoCam = document.getElementById('videoCam')
    const btnPlayPause = document.getElementById('btnPlayPause')

    this.slider = document.getElementById('slider')
    this.video = video
    this.videoCam = videoCam
    this.btnPlayPause = btnPlayPause

    const canvaScreen = document.getElementById('canvaScreen')
    const videoScreen = document.getElementById('videoScreen')
    await canvaScreen.getContext('2d').drawImage(videoScreen, 0, 0, videoScreen.videoWidth, videoScreen.videoHeight)

    this.thumb_start = canvaScreen.toDataURL()

    Trim()

    video.src = URL.createObjectURL(this.videoScreen)

    getBlobDuration(this.videoScreen).then((duration) => {
      this.total_time = duration * 1000
      this.final_time = this.total_time
    })

    // slider.noUiSlider.on('change', (values, handle) => {
    //   const old_start = start_element[0].innerHTML
    //   const old_finish = finish_element[0].innerHTML
    //   const slider_values = slider.noUiSlider.get()
    //   const start_string = new Date(slider_values[0] * 1000).toISOString().substr(11, 8)
    //   const finish_string = new Date(slider_values[1] * 1000).toISOString().substr(11, 8)
    //   const duration = new Date((slider_values[1] - slider_values[0]) * 1000).toISOString().substr(11, 8)
    //   start_element[0].innerHTML = start_string
    //   start_element[1].innerHTML = start_string
    //   finish_element[0].innerHTML = finish_string
    //   finish_element[1].innerHTML = duration
    //   if (old_start !== start_string) {
    //     createThumb(start_string, 'start')
    //   }
    //   if (old_finish !== finish_string) {
    //     createThumb(finish_string, 'finish')
    //   }
    // })
  },
  methods: {
    goBack() {
      // Navega para a página anterior no histórico do navegador
      window.history.back();
    },
    async trimVideo() {
      const formData = new FormData()
      formData.append('screen', this.screen)
      formData.append('webcam', this.webcam)
      formData.append('start_time', this.start_time_string)
      formData.append('final_time', this.final_time_string)

      this.loading = true
      
      const api = await CoursesServices().trimVideo(formData, this.$route.params.lesson_id)
      
      /*
      return console.log([
        {'screen': this.screen},
        {'webcam': this.webcam},
        {'start_time': this.start_time},
        {'final_time': this.final_time},
      ])
      */
     
      if (api.status === 200) {
        // exibir o botão para enviar para o servidor do vimeo
        this.videoScreen = `${process.env.VUE_APP_API_BASE}/${api.data.trimmedScreen}`
        this.videoWebcam = `${process.env.VUE_APP_API_BASE}/${api.data.trimmedWebcam}`

        // create the files
        this.video_screen = await this.createFile(this.videoScreen, 'video-tela.mp4')
        this.video_cam = await this.createFile(this.videoWebcam, 'video-webcam.mp4')

        console.log(this.video_cam)

        this.loading = false
        this.showSendBtn = true
      }

      this.loading = false
    },
    async createFile(url, fileName) {
      const options = { method: 'GET' }
      const response = await fetch(url, options).then(r => r.blob())

      return new File([response], fileName)
    },
    async sendToVimeo() {
      const formData = new FormData()
      formData.append('video_screen', this.video_screen)
      formData.append('video_cam', this.video_cam)
      this.sending = true
      const api = await CoursesService().sendToVimeo(formData)
      if (api.status === 200) {
        // eslint-disable-next-line no-alert
        alert('enviado!')
        this.sending = false
      }

      this.sending = false
    },
    playPauseVideo() {
      if (this.video.paused || this.video.ended) {
      // Change the button to a pause button
        const ref = this
        this.changeButtonType(this.btnPlayPause, 'pause')

        // start at position
        this.video.currentTime = (this.start_time / 1000)
        // stop at position
        this.video.addEventListener('timeupdate', function () {
          console.log(`current: ${this.currentTime}`)
          console.log(`end: ${ref.final_time}`)
          if (this.currentTime >= (ref.final_time / 1000)) {
            this.pause()
          }
        })

        // start at position
        this.videoCam.currentTime = (this.start_time / 1000)
        // stop at position
        this.videoCam.addEventListener('timeupdate', function () {
          // console.log(`current: ${this.currentTime}`)
          if (this.currentTime >= (ref.final_time / 1000)) {
            this.pause()
          }
        })
        this.video.play()
        this.videoCam.play()
      } else {
      // Change the button to a play button
        this.changeButtonType(this.btnPlayPause, 'play')
        this.video.pause()
        this.videoCam.pause()
      }
    },
    stopVideo() {
      this.video.pause()

      if (this.video.currentTime) {
        this.video.currentTime = 0
      }
    },
    changeButtonType(btn, value, classname) {
    // btn.title     = value;
      btn.innerHTML = value
      btn.className = classname
    }
  }
}
</script>

<style scoped>

</style>
